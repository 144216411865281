:root {
    --hanBlue2: #303133;
    --yBlue: #3956A5;
    --hanBlue: #5169CC;
    --cornflowerBlue: #7E91D0;
    --maxBluePurple: #99AFEB;
    --aliceBlue: #E9EFFC;
    --gray: #A5A5A5;
    --hanGray: #eef2f4;
    --white: #F8F8F8;
}
.wrapper {
    width: 373px;
    height: 680px;
    margin: 5vh auto 0px;
    position: relative;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

 /* Extra small devices */
 @media only screen and (min-width: 360px) and (max-width: 413px) {
    .wrapper {
        width: 358px;
        height: 680px;
    }
 }

 /* Extra small devices */
 @media only screen and (min-width: 414px) and (max-width: 427px) {
    .wrapper {
        width: 405px;
        height: 740px;
    }
 }

 /* Wider devices, tablet and Desktops */
 @media only screen and (min-width: 428px) {
    .wrapper {
        width: 420px;
        height: 765px;
    }
 }

.header {
    width: 100%;
    height: 50%;
    position: relative;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

#header-gradient {
    background: #303133;
    background: -moz-linear-gradient(47deg, rgba(70,88,164,1) 0%, rgba(81,105,204,1) 43%, rgba(70,88,164,1) 98%);
    background: -webkit-linear-gradient(47deg, rgba(70,88,164,1) 0%, rgba(81,105,204,1) 43%, rgba(70,88,164,1) 98%);
    background: linear-gradient(47deg, rgba(70,88,164,1) 0%, rgba(81,105,204,1) 43%, rgba(70,88,164,1) 98%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#4658a4",endColorstr="#4658a4",GradientType=1); 
}

.logo {
    width: 52px;
    position: absolute;
    top: 10%;
    left: 10%;   
    z-index: 2;
}

.infos {
    width: 90%;
    height: 50%;
    margin-top: 25%;
    margin-left: 10%;
    color: #F5F5F5FF;    
}

.profile-picture {
    width: 35%;
    height: auto;
    position: absolute;
    right: 5%;
    bottom: 5%;
    z-index: 1;
}

.triangle {
    width: 100%;
    bottom: 0%;
    border-width: 0 0 130px 373px;
    border-style: solid;
    position: absolute;
    margin-bottom: 0;
    padding-bottom: 0;
    border-top-color: transparent;
    border-right-color: transparent;
    border-left-color: transparent;
}

 /* Extra small devices */
 @media only screen and (min-width: 360px) and (max-width: 413px) {
    .triangle {
        border-width: 0 0 130px 358px;
    }
 }

 /* Extra small */
 @media only screen and (min-width: 414px) and (max-width: 427px) {
    .triangle {
        border-width: 0 0 130px 405px;
    }
 }

 /* Wider devices, tablet and Desktops */
 @media only screen and (min-width: 428px) {
    .triangle {
        border-width: 0 0 130px 420px;
    }
 }
@import './palette.css';



.menu {
    width: inherit;
    position: absolute;
    --bottom-footer: 0%;
    bottom: var(--bottom-footer);
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px; 
    z-index: 2;
    overflow: hidden;
}

#footer {
    --height: 50%;
    height: var(--height);
    transform: translate(0, 85%, 0);
    transition: height 0.5s, transform .8s cubic-bezier(.16,.81,.71,.41);
}

#footer.hide {
    height: 5%;
    transform: translate(0, 0%);
}

#footer.show {
    height: var(--height);
    transform: translate(0, 0);
}

.button-toggle-menu {
    margin: 0;
    padding: 0;
    border: none;
    background: none;
    cursor: pointer;
}

.slide-button {
    color: var(--blue4);
}

button:focus {
    outline: none;
}

.content {
    margin: 0 10% 0;
}
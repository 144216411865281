:root {
  --smoky-black: #111110ff;
  --eerie-black: #1D2025ff;
  --jet: #2E2E2Eff;
  --charcoal: #2E445Bff;
  --prussian-blue: #152D49ff;
  --black-coral: #455A6Dff;
  --light-steel-blue: #B8C4D1ff;
  --indigo: #51008FFF;
  --keppel: #0CB7A7ff;
  --granny-smith-apple: #ACED97ff;
  --quick-silver: #A3A3A3ff;
}

* {
  margin: 0;
  padding: 0;
}

html {
  box-sizing: border-box;
  font-size: 62.5%; /* 16px | 100% = 16px */
}
